import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../helpers/constants";
import axios from "axios";
import {
  Steps,
  Button,
  Input,
  Spin,
  notification,
  message,
  InputNumber,
  Checkbox,
} from "antd";
import PhoneNumberInput from "react-phone-number-input";
import { LoadingOutlined } from "@ant-design/icons";
import DataTable from "react-data-table-component";
import PaymentSteps from "../components/easyLinkEasyPay/modal/paymentSteps";
import ProductPreviewModal from "./productPreviewModal";
import { IoEyeOutline } from "react-icons/io5";
import { handleLocationDecode } from "../functions/decodeLocation";
import LocationSelector from "../components/shared/locationSelector";

const EasyLinkEasyPay = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [buyerPhone, setBuyerPhone] = useState<any>("");
  const [activePayMethod, setActivePayMethod] = useState(0);

  // trigger delivery address input
  const [triggerDeliveryAddress, setTriggerDeliveryAddress] = useState(false);
  const [locationLat, setLocationLat] = useState<any>(null);
  const [locationLong, setLocationLong] = useState<any>(null);
  const [locationPlaceName, setLocationPlaceName] = useState<any>(null);
  // const [cantFindLocation, setCantFindLocation] = useState(false);

  //actualRespons
  const [actualResponse, setActualResponse] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [isBusy, setIsBusy] = useState(false);
  const [filtered, setFiltered] = useState<any>([]);

  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [quantities, setQuantities] = useState<Record<string, number>>({});

  //modal
  const [openSteps, setOpenSteps] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);

  //onload
  useEffect(() => {
    const GetEasyLinkDetails = async () => {
      setIsFetching(true);
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/elep/url/${id}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response?.data?.data) {
          const url = new URL(response?.data?.data);
          const elep_id = url.searchParams.get("elep_id");

          const mainRes = await axios.get(
            `${BASE_URL}/api/v1/elep?elep_id=${elep_id}`
          );
          console.log("___mainRes:", mainRes?.data);
          setActualResponse(mainRes?.data?.data);
          setIsFetching(false);
        } else {
          notification.error({
            message: "Error!",
            description: "Could not retrieve your data.",
          });
          navigate("/");
        }
      } catch (error: any) {
        console.log("___error:", error);
        notification.error({
          message: "Error",
          description:
            error?.response?.data?.data ||
            "An error occurred. Please try again!",
        });
        navigate("/");
      }
    };

    if (id) {
      console.log("___easyId:", id);
      GetEasyLinkDetails();
    }

    // eslint-disable-next-line
  }, [id]);

  //track quantity input changes by row?._id
  const handleQuantityChange = (id: string, value: number) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  //calculateSubtotal
  const calculateSubtotal = () => {
    const data =
      selectedRows?.length === 0 ? actualResponse?.items : selectedRows;

    return data
      ?.reduce((total: number, item: any) => {
        const quantity = quantities[item?._id] || 1;
        return total + item?.price * quantity;
      }, 0)
      .toFixed(2);
  };

  useEffect(() => {
    calculateSubtotal();

    // eslint-disable-next-line
  }, [selectedRows, quantities]);

  // table columns
  const columns: any = [
    {
      name: "Name",
      cell: (row: any) => (
        <div>
          <div className="flex gap-2 items-center pt-2">
            {row?.images?.map((imgUrl: any, i: number) => (
              <div
                key={i}
                className="h-[52px] w-[54px] bg-[#D9D9D9]rounded-[6px]"
              >
                <img
                  src={imgUrl}
                  alt=""
                  className="w-full h-full object-cover rounded-[6px]"
                />
              </div>
            ))}
          </div>
          <div className="mt-2 pb-2">
            <span className="line-clamp-1">{row?.name}</span>
            <p className="text-xs text-gray-500 line-clamp-2">
              {row?.description}
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Qty In Stock",
      selector: (row: any) => row?.qty_available,
      center: true,
    },
    {
      name: "Qty To Buy",
      cell: (row: any) => (
        <InputNumber
          min={1}
          max={row?.qty_available}
          value={quantities[row?._id] || 1}
          onChange={(value: any) => handleQuantityChange(row?._id, value)}
        />
      ),
      center: true,
    },
    {
      name: "Price",
      selector: (row: any) => Number(row?.price).toFixed(2),
      center: true,
    },
    {
      name: "",
      selector: (row: any) => (
        <IoEyeOutline
          className="h-5 w-5 cursor-pointer"
          onClick={() => {
            setSelectedProduct(row);
            setOpenPreview(true);
          }}
        />
      ),
    },
  ];

  // Steps array for display
  const steps = [
    {
      name: "Deal Details",
      description: "Kindly confirm deal products",
    },
    {
      name: "Seller Information",
      description: "Kindly confirm seller's information",
    },
    {
      name: "Make Payment",
      description: "Kindly complete the payment",
    },
  ];

  //payMethods
  const payMethods = [
    "Mobile Money",
    // "Credit Card"
  ];

  //handleMakePayment
  const handleMakePayment = async () => {
    if (!buyerPhone) {
      message.info("Please enter your phone number");
    } else {
      let pay_data: any = {
        buyer_phone_number: buyerPhone?.replace("+233", "+233 "),
        buyer_account_type: activePayMethod === 0 ? "MOMO" : "CARD",
        elep_id: actualResponse?._id,
        buyer_selected_items:
          selectedRows?.length === 0
            ? actualResponse?.items?.map((item: any) => ({
                id: item?._id,
                quantity: quantities[item?._id] || 1,
              }))
            : selectedRows?.map((item: any) => ({
                id: item?._id,
                quantity: quantities[item?._id] || 1,
              })),
        has_extra_conditions: true,
        extra_conditions_for_payment: {
          is_timed: false,
          time_to_close_deal: "",
          splits: 0,
          payments: [
            {
              amount:
                selectedRows?.length === 0
                  ? Number(0).toFixed(2)
                  : calculateSubtotal(),
              payment_note: "",
              date_time: "",
            },
          ],
        },
        chose_delivery: triggerDeliveryAddress,
        destination_location: {
          address: locationPlaceName,
          lat: `${locationLat}`,
          long: `${locationLong}`,
        },
      };

      if (!triggerDeliveryAddress) {
        delete pay_data?.destination_location;
      }

      console.log("___payData:", pay_data);
      setIsBusy(true);

      try {
        const response = await axios.post(
          `${BASE_URL}/api/v1/elep/buy`,
          pay_data
        );
        console.log("__payRes:", response?.data);
        if (response?.data?.data === "You cannot make a deal with yourself") {
          notification.error({
            message: "Error!",
            description: "You cannot make a deal with yourself",
          });
          setIsBusy(false);
          return;
        } else {
          setOpenSteps(true);
          notification.info({
            message: "Deal Initiated!",
            description:
              "Please confirm payment on your mobile device to complete the purchase.",
            duration: 5,
          });
        }
      } catch (error: any) {
        console.log("___payErr:", error);
        notification.error({
          message: "Error!",
          description:
            error?.response?.data?.data ||
            error?.response?.data?.message ||
            "An unknown error occured. Please retry",
        });
        setIsBusy(false);
      }
    }
  };

  //handleSearchFilter
  const handleSearchFilter = (e: any) => {
    const searchValue = e?.target?.value;
    if (searchValue) {
      const filteredData = actualResponse?.items.filter((item: any) =>
        item?.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFiltered((prev: any) => ({ ...prev, items: filteredData }));
    } else {
      setFiltered([]);
    }
  };

  // handle location slected
  const handleLocationSlected = (selected: any) => {
    if (selected) {
      console.log(selected.value);
      handleLocationDecode(selected)
        .then((response) => {
          const { latitude, longitude, placeName } = response;
          setLocationLat(latitude);
          setLocationLong(longitude);
          setLocationPlaceName(placeName);
        })
        .catch((error) => {
          console.error("An unexpected error occurred:", error);
        });
    } else {
      console.log("No option selected");
      setLocationPlaceName("");
    }
  };

  // Stepper component content for each step
  const stepperComponent = [
    //step 1
    <div key="step1">
      <div className="flex justify-end mb-5">
        <Input
          className="2xl:w-[150px] xl:w-[150px] lg:w-[150px] md:w-[150px] w-full"
          placeholder="Search item"
          onChange={handleSearchFilter}
          allowClear
        />
      </div>

      <DataTable
        columns={columns}
        data={filtered?.length === 0 ? actualResponse?.items : filtered?.items}
        selectableRows
        onSelectedRowsChange={({ selectedRows }) =>
          setSelectedRows(selectedRows)
        }
        highlightOnHover
        striped
        pointerOnHover
        onRowClicked={(row: any) => {
          setSelectedProduct(row);
          setOpenPreview(true);
        }}
      />

      {/* delivery address */}
      <div className="mt-[30px] flex justify-between lg:gap-10 gap-5">
        <Checkbox
          checked={triggerDeliveryAddress}
          onChange={() => setTriggerDeliveryAddress(!triggerDeliveryAddress)}
          className="flex items-center"
        >
          <p className="text-[14px] font-[400] text-black">
            Deliver items to my address
          </p>
        </Checkbox>

        {triggerDeliveryAddress && (
          <div className="flex-1">
            <LocationSelector
              onChange={handleLocationSlected}
              placeholder="Enter your delivery address"
              defalutValue={
                locationPlaceName && {
                  label: locationPlaceName,
                  value: locationPlaceName,
                }
              }
            />

            {/* <div className="flex justify-end mt-3">
              <Checkbox
                checked={cantFindLocation}
                onChange={() => setCantFindLocation(!cantFindLocation)}
              >
                <p className="text-[14px] font-[400] text-black">
                  Can't find my location
                </p>
              </Checkbox>
            </div> */}
          </div>
        )}
      </div>

      <div className="mt-[30px] p-5 bg-[#552130] bg-opacity-[5%]">
        <p className="text-[15px] font-[600]">Payment Summary</p>

        <div className="mt-[10px] flex justify-between items-center gap-5">
          <p className="text-[14px] font-[400] text-[#282828]">Sub Total</p>
          <p className="text-[14px] font-[600] text-[#282828]">
            GHS {calculateSubtotal()}
          </p>
        </div>
        <div className="mt-[10px] flex justify-between items-center gap-5">
          <p className="text-[14px] font-[400] text-[#282828]">E-Levy</p>
          <p className="text-[14px] font-[600] text-[#282828]">0.00</p>
        </div>

        <hr className="mt-[10px] border border-dashed" />

        <div className="mt-[10px] flex justify-between items-center gap-5">
          <p className="text-[16px] font-[600] text-[#282828]">Grand Total</p>
          <p className="text-[16px] font-[600] text-[#282828]">
            GHS {calculateSubtotal()}
          </p>
        </div>
      </div>
    </div>,
    //step 2
    <div key="step2" className="flex justify-center">
      <div className="mt-[50px] w-[430px]">
        <p className="text-[14px] font-[500] text-black mb-[7px]">
          Phone number
        </p>
        <PhoneNumberInput
          value={actualResponse?.phone_number}
          onChange={() => {}}
          defaultCountry="GH"
          countryOptionsOrder={["GH", "NG"]}
          className="w-full bg-[#fff] border border-gray-300 rounded-[6px] p-[10px] disabled:cursor-not-allowed"
          numberInputProps={{
            className:
              "focus:outline-none bg-[#fff] w-full disabled:cursor-not-allowed",
            placeholder: "Enter your phone number",
          }}
          disabled
        />
        <p className="text-[14px] font-[500] text-black mt-[7px]">
          Seller phone number
        </p>

        <div className="mt-[20px]">
          <p className="text-[14px] font-[500] text-black mb-[7px]">Email</p>
          <input
            placeholder="Enter your email"
            type="email"
            value={actualResponse?.email}
            onChange={() => {}}
            className="w-full bg-[#fff] border border-gray-300 rounded-[6px] p-[10px] focus:outline-none disabled:cursor-not-allowed"
            disabled
          />
        </div>
      </div>
    </div>,
    //step 3
    <div key="step3" className="flex justify-center">
      <div className="mt-[50px] w-[430px]">
        <div className="w-full flex items-center border-b bordr-b-[#5521301A]">
          {payMethods.map((method: any, index: number) => (
            <div
              key={index}
              className={`w-fit flex items-center gap-2 cursor-pointer ${
                activePayMethod === index
                  ? "border-[#552130] border-b-2 text-[#552130]"
                  : ""
              } px-5`}
              onClick={() => setActivePayMethod(index)}
            >
              <p className="text-[14px] font-[500]">{method}</p>
            </div>
          ))}
        </div>

        <div className="mt-[70px]">
          {
            //mobile money
            activePayMethod === 0 && (
              <div>
                <p className="text-[14px] font-[500] text-black mb-[7px]">
                  Mobile Money Number
                </p>
                <PhoneNumberInput
                  value={buyerPhone}
                  onChange={(value) => setBuyerPhone(value)}
                  defaultCountry="GH"
                  countryOptionsOrder={["GH", "NG"]}
                  className="w-full bg-[#fff] border border-[#3131311A] rounded-[6px] p-[10px]"
                  numberInputProps={{
                    className: "focus:outline-none bg-[#fff] w-full",
                    placeholder: "Enter your phone number",
                  }}
                />
              </div>
            )
          }

          {activePayMethod === 1 && (
            <div>
              <p className="text-[14px] font-[500] text-black mb-[7px]">
                Name on card
              </p>
              <Input
                placeholder="Enter name on card"
                className="w-full bg-[#fff] border border-[#3131311A] rounded-[6px] p-[10px] focus:outline-none"
              />

              <div className="mt-5">
                <p className="text-[14px] font-[500] text-black mb-[7px]">
                  Card Number
                </p>
                <Input
                  placeholder="Enter card number"
                  className="w-full bg-[#fff] border border-[#3131311A] rounded-[6px] p-[10px] focus:outline-none"
                />
              </div>

              <div className="mt-5 grid grid-cols-2 gap-5">
                <div>
                  <p className="text-[14px] font-[500] text-black mb-[7px]">
                    Expiry Date
                  </p>
                  <Input
                    placeholder="MM/YYYY"
                    className="w-full bg-[#fff] border border-[#3131311A] rounded-[6px] p-[10px] focus:outline-none"
                    minLength={7}
                  />
                </div>

                <div>
                  <p className="text-[14px] font-[500] text-black mb-[7px]">
                    CVV
                  </p>
                  <Input
                    placeholder="***"
                    className="w-full bg-[#fff] border border-[#3131311A] rounded-[6px] p-[10px] focus:outline-none"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>,
  ];

  // Function to handle step changes
  const next = () => {
    if (currentStep === 0 && selectedRows.length === 0) {
      message.info("Please select at least one item to proceed");
    } else {
      setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
    }
  };

  const prev = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  return (
    <>
      <div className="h-screen w-full overflow-hidden bg-[#FFFFFF]">
        {isFetching ? (
          <div className="h-full w-full flex justify-center items-center">
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: 48, color: "#000" }} spin />
              }
            />
          </div>
        ) : (
          <div className="w-full h-full 2xl:px-[230px] xl:px-[230px] lg:px-[200px] md:px-[130px] px-[20px] 2xl:py-[60px] xl:py-[60px] lg:py-[60px] md:py-[40px] py-[20px] overflow-y-auto">
            <Steps current={currentStep}>
              {steps.map((step, index) => (
                <Steps.Step
                  key={index}
                  title={step.name}
                  description={step.description}
                  className="custom-step"
                />
              ))}
            </Steps>

            {/* Display the content of the current step */}
            <div className="mt-[100px] px-10">
              {stepperComponent[currentStep]}
            </div>

            {/* Navigation buttons */}
            <div className="mt-[70px] flex justify-between items-center px-10">
              <Button
                onClick={prev}
                disabled={currentStep === 0 || isBusy}
                style={{ color: "#552130", borderColor: "#552130", height: 40 }}
              >
                Previous
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "#552130", height: 40 }}
                disabled={isBusy}
                onClick={
                  currentStep === steps.length - 1 ? handleMakePayment : next
                }
              >
                {currentStep === steps.length - 1 ? (
                  isBusy ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24, color: "#fff" }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Make Payment"
                  )
                ) : (
                  "Continue"
                )}
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* Payment Steps Modal */}
      {openSteps && (
        <PaymentSteps
          isOpened={openSteps}
          handleClose={() => setOpenSteps(false)}
        />
      )}

      {/* preview product */}
      {openPreview && (
        <ProductPreviewModal
          isOpened={openPreview}
          handleClose={() => setOpenPreview(false)}
          selectedProduct={selectedProduct}
        />
      )}
    </>
  );
};

export default EasyLinkEasyPay;
