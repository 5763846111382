import { useState } from "react";
import { Link } from "react-router-dom";

const Menu = ({ isScrolled }: { isScrolled: boolean }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <div
      className={`w-full h-[110px] fixed left-0 top-0 flex justify-center items-center shadow-md z-50 transition-all duration-300 ${
        isScrolled ? "bg-white" : "bg-transparent"
      }`}
    >
      <div className="w-full flex justify-between items-center 2xl:px-[200px] xl:px-[80px] lg:px-[80px] md:px-[80px] px-[20px]">
        <div className="flex items-center gap-10">
          <div className="flex items-center gap-[15px]">
            <img
              className="w-[50.33px] h-[61.82px]"
              src="/images/peacemakerLogo.png"
              alt="Logo"
            />
          </div>
          <div className="hidden lg:flex items-center gap-8">
            <a href="#header">
              <div className="flex items-center">
                <div
                  className={`${
                    isScrolled ? "text-slate-700" : "text-slate-300"
                  } text-base font-semibold leading-normal`}
                >
                  Home
                </div>
              </div>
            </a>
            <a href="#about">
              <div className="py-1 flex flex-col items-center">
                <div className="flex items-center">
                  <div
                    className={`${
                      isScrolled ? "text-slate-700" : "text-slate-300"
                    } text-base font-semibold leading-normal`}
                  >
                    About
                  </div>
                </div>
              </div>
            </a>

            <a href="#how">
              <div className="py-1 flex flex-col items-center">
                <div className="flex items-center">
                  <div
                    className={`${
                      isScrolled ? "text-slate-700" : "text-slate-300"
                    } text-base font-semibold leading-normal`}
                  >
                    How It Works
                  </div>
                </div>
              </div>
            </a>

            <a href="#branches">
              <div className="flex items-center">
                <div
                  className={`${
                    isScrolled ? "text-slate-700" : "text-slate-300"
                  } text-base font-semibold leading-normal`}
                >
                  Locations
                </div>
              </div>
            </a>

            <a href="#app">
              <div className="flex items-center">
                <div
                  className={`${
                    isScrolled ? "text-slate-700" : "text-slate-300"
                  } text-base font-semibold leading-normal`}
                >
                  Get The App
                </div>
              </div>
            </a>

            <Link to="https://app.peacemaker.cash/">
              <div className="flex items-center">
                <div
                  className={`${
                    isScrolled ? "text-slate-700" : "text-slate-300"
                  } text-base font-semibold leading-normal`}
                >
                  Get An Account
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="hidden lg:flex items-center gap-[41px]">
          <div className="flex items-center gap-3">
            <a href="https://play.google.com/store/apps/details?id=com.peacemaker.android">
              <img
                className="w-[135px] h-10 bg-black rounded-[5px] border border-[#a6a6a6]"
                src="/images/google.png"
                alt="Google Play"
              />
            </a>
            <a href="https://appstore.com">
              <img
                className="w-[120px] h-10 bg-[#0c0d10] rounded-[7px] border border-[#a6a6a6]"
                src="/images/apple.png"
                alt="App Store"
              />
            </a>
          </div>
        </div>
        <div className="lg:hidden flex items-center">
          <button
            onClick={toggleNav}
            className={`${
              isScrolled ? "text-[#475466]" : "text-white"
            } text-base font-semibold leading-normal`}
          >
            Menu
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isNavOpen && (
        <div className="w-full flex flex-col items-center bg-white shadow-md lg:hidden absolute top-[110px]">
          <div className="flex flex-col items-center gap-4 py-4">
            <div className="text-[#475466] text-base font-semibold leading-normal">
              Home
            </div>
            <div className="text-[#475466] text-base font-semibold leading-normal">
              Products
            </div>
            <div className="text-[#475466] text-base font-semibold leading-normal">
              Resources
            </div>
            <div className="text-[#475466] text-base font-semibold leading-normal">
              Rates
            </div>
          </div>
          <div className="flex items-center gap-3 pb-4">
            <img
              className="w-[135px] h-10 bg-black rounded-[5px] border border-[#a6a6a6]"
              src="/images/google.png"
              alt="Google Play"
            />
            <img
              className="w-[120px] h-10 bg-[#0c0d10] rounded-[7px] border border-[#a6a6a6]"
              src="/images/apple.png"
              alt="App Store"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
