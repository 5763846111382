import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GOOGLE_MAP_KEY } from "../../helpers/constants";

interface selectProps {
  onChange: (data: any) => void;
  placeholder?: string;
  disabled?: boolean;
  id?: any;
  defalutValue?: any;
}

const LocationSelector = ({
  onChange,
  placeholder,
  disabled,
  id,
  defalutValue,
}: selectProps) => {
  return (
    <>
      <GooglePlacesAutocomplete
        apiKey={GOOGLE_MAP_KEY}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["gh"],
          },
        }}
        selectProps={{
          id: id,
          className: "border-none",
          placeholder: placeholder,
          isClearable: true,
          isSearchable: true,
          onChange: (selected: any) => onChange(selected),
          isDisabled: disabled,
          defaultValue: defalutValue,
        }}
      />
    </>
  );
};

export default LocationSelector;
